import {RestAPI as API} from "@aws-amplify/api-rest";
import {Storage} from "@aws-amplify/storage";

const API_NAME = 'admin';
const API_CORE_URL = '/admin/core';
const API_BALANCER_URL = '/admin/balancer';
const GSMF_MERCHANT_URL = '/admin/gsmf';
const B24_API_URL = '/admin/b24';
const BORNEO_API_URL = '/admin/borneo';

export function getBalancerStatus(key) {
    let path = key ? `${API_CORE_URL}/balancer-status?key=${key}` : `${API_CORE_URL}/balancer-status`;
    return API.get(API_NAME, path, {});
}

export function switchLoadBalancerActivation(key) {
    return API.put(API_NAME, `${API_BALANCER_URL}/${key}/load-balancer`, {body: key});
}

export function switchBaseEndpoint(key) {
    return API.put(API_NAME, `${API_BALANCER_URL}/${key}/endpoint`, {body: key});
}

export function getAuditsLogs(startDate, endDate, userName) {
    userName = userName ? userName : '';
    startDate = startDate ? startDate : new Date();
    startDate.setHours(0, 0, 0, 0);
    endDate = endDate ? endDate : new Date();
    endDate.setHours(23, 59, 59, 59);
    let url = `${API_CORE_URL}/audit-logs?userName=${userName}&startDate=${startDate.getTime()}&endDate=${endDate.getTime()}`;
    return API.get(API_NAME, url, {});
}

export function getRefunds(id, refundStatus) {
    return new Promise((resolve, reject) => {
        let path = `${API_CORE_URL}/tx-history?id=${id}`;
        if (refundStatus) {
            path += `&refundStatus=${refundStatus}`;
        }
        API.get(API_NAME, path, {})
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
}

export function forceRefund(saleid) {
    let init = {
        body: {
            saleid: saleid.toString()
        }
    }
    return API.put(API_NAME, `${API_CORE_URL}/force-refund`, init);
}

export function partialRefund(sale) {
    let init = {
        body: {
            sale: JSON.stringify(sale)
        }
    }

    return API.put(API_NAME, `${API_CORE_URL}/partial-refund`, init);
}

export function remoteRefund(tx) {
    let init = {
        body: JSON.stringify(tx)
    }

    return API.put(API_NAME, `${API_CORE_URL}/remote-refund`, init);
}

export function setAmxPsp(amxPsp, prosaFiid) {
    let init = {
        body: {
            psp: amxPsp,
            prosaFiid: prosaFiid
        }
    }

    return API.put(API_NAME, `${API_CORE_URL}/amx-psp`, init);
}

export function getFiids() {
    let path = `${API_CORE_URL}/amx-psp`;
    return API.get(API_NAME, path, {});
}

export function getUserData(userId) {
    let path = `${API_CORE_URL}/gsmf-user?id=${userId}`;

    return API.get(API_NAME, path, {});
}

export function feeRetention(txId) {
    let init = {body: txId}

    return API.put(API_NAME, `${API_CORE_URL}/fee-retention`, init);
}

export function generateBatchFile(users) {
    let init = {
        body: users
    }

    return API.put(API_NAME, `${API_CORE_URL}/generate-file`, init);
}

export function saveUserReader(userReader) {
    return API.put(API_NAME, `${API_CORE_URL}/save-user-reader`, {body: userReader});
}

export function updateUserReader(userReader) {
    return API.put(API_NAME, `${API_CORE_URL}/update-user-reader`, {body: userReader});
}

export function amexDataByFiid(fiid) {
    let path = `${API_CORE_URL}/amex-data-by-fiid?fiid=${fiid}`;
    return API.get(API_NAME, path, {});
}

export function gsmfSearchMerchants(queryParams = {}) {
    let path = `${GSMF_MERCHANT_URL}/merchant/search`;
    const searchParams = new URLSearchParams();

    for (const key in queryParams) {
        if (queryParams[key] !== null && queryParams[key] !== undefined) {
            searchParams.append(key, queryParams[key]);
        }
    }

    const fullPath = searchParams.toString() ? `${path}?${searchParams}` : path;

    return API.get(API_NAME, fullPath, {});
}

export function getGsmfHistory(userId, status) {
    let path = `${GSMF_MERCHANT_URL}/${userId}/history`;
    return API.get(API_NAME, path, {})
        .then(resp => {
            if (typeof resp.status_code !== 'undefined' && resp.status_code !== 200) {
                console.error('There was a problem with the fetch operation');
                resp = {}
            }
            return resp;
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
            return {};
        });
}

export function gsmfUpsertMerchants(userId, status) {
    let path = `${GSMF_MERCHANT_URL}/${userId}/${status}`;

    return API.put(API_NAME, path, {});
}

export function uploadUserReaderFile(file) {
    let formData = new FormData();
    formData.append('file', file);

    let fileName = `${new Date().getTime()}_${file.name}`;

    return Storage.put(fileName, file, {level: 'private'});
}

export function searchB24Tx(params) {
    const {startDate, endDate, t2m, authCode, fiid, bpTxId, amount} = params;
    const requestBody = Object.entries({startDate, endDate, t2m, authCode, fiid, bpTxId, amount})
        .reduce((acc, [key, value]) => {
            if (value !== undefined && value !== null) {
                acc[key] = value;
            }
            return acc;
        }, {});

    let path = `${B24_API_URL}/tx/search`;

    return API.post(API_NAME, path, {body: requestBody})
        .then(resp => {
            if (typeof resp.status_code !== 'undefined' && resp.status_code !== 200) {
                throw new Error(resp.message);
            }
            return resp;
        })
        .then(resp => {
            if (Array.isArray(resp.data) && resp.data.length === 0) {
                throw new Error('Data is an empty array');
            }
            return resp;
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        });
}

export function getB24TxById(params) {
    const {bpTxId} = params;

    let path = `${B24_API_URL}/tx/bp/${bpTxId}`;

    return API.get(API_NAME, path, {})
        .then(resp => {
            if (typeof resp.status_code !== 'undefined' && resp.status_code !== 200) {
                throw new Error(resp.message);
            }
            return resp;
        })
        .then(resp => {
            if (Array.isArray(resp.data) && resp.data.length === 0) {
                throw new Error('Data is an empty array');
            }
            return resp;
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        });
}

export function getBorneoTxById(params) {
    const {bpTxId} = params;

    let path = `${BORNEO_API_URL}/tx/kushki/${bpTxId}`;

    return API.get(API_NAME, path, {})
        .then(resp => {
            if (typeof resp.status_code !== 'undefined' && resp.status_code !== 200) {
                throw new Error(resp.message);
            }
            return resp;
        })
        .then(resp => {
            if (Array.isArray(resp.data) && resp.data.length === 0) {
                throw new Error('Data is an empty array');
            }
            return resp;
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        });
}

export function fixB24Tx(params) {
    const {txUid, authCode} = params;
    const requestBody = Object.entries({txUid, authCode})
        .reduce((acc, [key, value]) => {
            if (value !== undefined && value !== null) {
                acc[key] = value;
            }
            return acc;
        }, {});

    let path = `${B24_API_URL}/tx/fix`;

    return API.put(API_NAME, path, {body: requestBody})
        .then(resp => {
            if (typeof resp.status_code !== 'undefined' && resp.status_code !== 200) {
                throw new Error(resp.message);
            }
            return resp;
        })
        .then(resp => {
            if (Array.isArray(resp.data) && resp.data.length === 0) {
                throw new Error('Data is an empty array');
            }
            return resp;
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        });
}

export function refundB24Tx(params) {
    const {
        txUid,
        authCode,
        t2m,
        fiid,
        tsn,
        amount
    } = params;

    const requestBody = Object.entries({
        txUid,
        authCode,
        t2m,
        fiid,
        tsn,
        amount
    }).reduce((acc, [key, value]) => {
        if (value !== undefined && value !== null) {
            acc[key] = value;
        }
        return acc;
    }, {});

    let path = `${B24_API_URL}/tx/refund`;

    return API.put(API_NAME, path, {body: requestBody})
        .then(resp => {
            if (typeof resp.status_code !== 'undefined' && resp.status_code !== 200) {
                throw new Error(resp.message);
            }
            return resp;
        })
        .then(resp => {
            if (Array.isArray(resp.data) && resp.data.length === 0) {
                throw new Error('Data is an empty array');
            }
            return resp;
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        });
}

export function refundBorneoTx(params) {
    const {
        txUid
    } = params;

    const requestBody = Object.entries({
        txUid
    }).reduce((acc, [key, value]) => {
        if (value !== undefined && value !== null) {
            acc[key] = value;
        }
        return acc;
    }, {});

    let path = `${BORNEO_API_URL}/tx/kushki/refund`;

    return API.post(API_NAME, path, {body: requestBody})
        .then(resp => {
            if (typeof resp.status_code !== 'undefined' && resp.status_code !== 200) {
                throw new Error(resp.message);
            }
            return resp;
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        });
}