import React, {Component} from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    ButtonToolbar,
    Card,
    Col,
    Form,
    FormControl,
    InputGroup,
    Modal,
    OverlayTrigger,
    Row,
    Spinner,
    Tab,
    Table,
    Tabs,
    Tooltip
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {CalendarIcon, TrashIcon} from "@primer/octicons-react";
import {
    fixB24Tx,
    refundB24Tx,
    refundBorneoTx,
    searchB24Tx,
    getB24TxById,
    getBorneoTxById
} from "../../api/CoreHandlerApi";
import ReactJson from "react-json-view";
import * as XLSX from "xlsx";

const FORMAT_DATEPICKER = 'dd-MM-yyyy';
const TAB = {
    SEARCH: 1,
    B24_FILE_PROCESSING: 2,
    BORNEO_FILE_PROCESSING: 3
};
const DIALOG_STATUS = {
    SUCCESS: 'success',
    ERROR: 'danger',
    INFO: 'info'
};
const FILE_TYPES = {
    B24: 'B24',
    BORNEO: 'BORNEO',
};

const CustomInputDatepicker = ({onClick}) => (
    <Button variant="outline-secondary" onClick={onClick}><CalendarIcon/></Button>
);


export class B24OperationsPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: TAB.SEARCH,
            spinner: false,
            authCodes: undefined,
            fixB24TxConfirmation: {},
            refundB24TxConfirmation: {},
            b24TxFileProcessing: {},
            txOperationNotificationBar: undefined,
            searchB24TxResultMap: undefined,
            startDate: undefined,
            endDate: undefined,
            t2m: undefined,
            authCode: undefined,
            fiid: undefined,
            amount: undefined,
            bpTransactionId: undefined,
            isT2mValid: false,
            isAuthCodeValid: true,
            isValidAmount: false,
            isFiidValid: true,
            isBpTransactionIdValid: true,
            areFieldsComplete: false
        }

        this.formatDatePickerText = this.formatDatePickerText.bind(this);
        this.onChangeTabs = this.onChangeTabs.bind(this);
    }

    formatDatePickerText(date, text) {
        if (date) {
            return this.formatDate(date);
        } else {
            return 'Fecha ' + text;
        }
    }

    formatDate(date) {
        if (date) {
            let day = date.getDate();
            day = day < 10 ? '0' + day : day;
            let month = date.getMonth() + 1;
            month = month < 10 ? '0' + month : month;
            let year = date.getFullYear();

            return [day, month, year].join('-');
        } else {
            return date;
        }
    }

    onChangeTabs(key) {
        let current = parseFloat(key);
        this.setState({
            activeTab: current
        });
    }

    setStartDate = (newStartDate) => {
        if (!this.state.endDate || newStartDate <= this.state.endDate) {
            this.setState({startDate: newStartDate}, this.updateFieldCompleteness);
        } else {
            this.setState({startDate: newStartDate, endDate: newStartDate}, this.updateFieldCompleteness);
        }
    }

    setEndDate = (newEndDate) => {
        if (!this.state.startDate || newEndDate >= this.state.startDate) {
            this.setState({endDate: newEndDate}, this.updateFieldCompleteness);
        } else {
            this.setState({endDate: newEndDate, startDate: newEndDate}, this.updateFieldCompleteness);
        }
    }

    validateT2m = (input) => {
        const regex = /^\d{6}\*{6}\d{4}$/;
        return regex.test(input);
    };

    setT2Mask = (value) => {
        let current = value.trim();
        this.setState({t2m: current});
        const isT2mValid = this.validateT2m(current);
        this.setState({isT2mValid}, this.updateFieldCompleteness);
    };

    validateAuthCode = (input) => {
        const regex = /^[a-zA-Z0-9]{1,6}$/;
        return regex.test(input);
    };

    validateBPTransactionId = (input) => {
        const regex = /^[1-9]\d*$/;
        return regex.test(input);
    };

    setBpTransactionId = (value) => {
        let current = value.trim();
        this.setState({bpTransactionId: current});
        const isBpTransactionIdValid = this.validateBPTransactionId(current) || (typeof current === 'string' && current === '');
        this.setState({isBpTransactionIdValid}, this.updateFieldCompleteness);
    };

    setAuthCode = (value) => {
        let current = value.trim();
        this.setState({authCode: current});
        const isAuthCodeValid = this.validateAuthCode(current) || (typeof current === 'string' && current === '');
        this.setState({isAuthCodeValid}, this.updateFieldCompleteness);
    };

    validateAmount = (input) => {
        const regex = /^\d+(\.\d{1,2})?$/;
        return regex.test(input);
    };

    setAmount = (value) => {
        let current = value.trim();
        this.setState({amount: current});
        const isValidAmount = this.validateAmount(current);
        this.setState({isValidAmount}, this.updateFieldCompleteness);
    };

    validateFiid = (input) => {
        const regex = /^\d{7}$/;
        return regex.test(input);
    };

    setFiid = (value) => {
        let current = value.trim();
        this.setState({fiid: current});
        const isFiidValid = this.validateFiid(current) || (typeof current === 'string' && current === '');
        this.setState({isFiidValid}, this.updateFieldCompleteness);
    };

    clearB24TxSearchFields = () => {
        this.setState({
            fixB24TxConfirmation: {},
            refundB24TxConfirmation: {},
            txOperationNotificationBar: undefined,
            searchB24TxResultMap: undefined,
            startDate: undefined,
            endDate: undefined,
            t2m: '',
            authCode: '',
            fiid: '',
            amount: '',
            bpTransactionId: '',
            isT2mValid: false,
            isAuthCodeValid: true,
            isValidAmount: false,
            isFiidValid: true,
            isBpTransactionIdValid: true,
            areFieldsComplete: false
        }, this.updateFieldCompleteness);
    }

    clearB24TxResultMap = () => {
        this.setState({
            searchB24TxResultMap: undefined
        }, this.updateFieldCompleteness);
    }

    configureTxOperationNotificationBar = (message, type, object) => {
        if (message !== undefined) {
            this.setState({
                txOperationNotificationBar: {
                    type: type || DIALOG_STATUS.INFO,
                    message: message,
                    object: object
                }
            });
        } else {
            this.setState({
                txOperationNotificationBar: undefined
            });
        }
    }

    searchB24Tx = () => {
        this.setState({spinner: true});
        this.configureTxOperationNotificationBar(undefined);

        let {startDate, endDate, authCode, fiid, bpTransactionId, ...otherStateProps} = this.state;

        let formattedStartDate = this.formatDate(startDate);
        let formattedEndDate = this.formatDate(endDate);
        let adjustedAuthCode = (!authCode || authCode.trim() === '') ? undefined : authCode;
        let adjustedFiid = (!fiid || fiid.trim() === '') ? undefined : fiid;
        let adjustedBpTransactionId = (!bpTransactionId || bpTransactionId.trim() === '') ? undefined : bpTransactionId;

        searchB24Tx({
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            authCode: adjustedAuthCode,
            fiid: adjustedFiid,
            bpTxId: adjustedBpTransactionId,
            ...otherStateProps
        })
            .then(this.onResults)
            .catch(this.onError);
    }

    onError = (e) => {
        console.error(e)
        this.setState({spinner: false});
        this.configureTxOperationNotificationBar(e.message, DIALOG_STATUS.ERROR);
        this.clearB24TxResultMap();
        setTimeout(() => {
            this.configureTxOperationNotificationBar(undefined);
        }, 5000);
    }

    onResults = (d) => {
        this.setState({spinner: false});
        this.setState({searchB24TxResultMap: d.data.reduce((acc, tx) => ({...acc, [tx.txUid]: tx}), {})});
        this.setB24TxSearchResultsAuthCodeMap(d.data);
    }

    createB24TxSearchResults = () => {
        let results = (this.state.searchB24TxResultMap && typeof this.state.searchB24TxResultMap === 'object') ? Object.values(this.state.searchB24TxResultMap) : [];

        return results.map(tx => (
            <tr key={tx.txUid}>
                <td>{tx.txUid}</td>
                <td>{tx.fiid}</td>
                <td>{tx.t2m}</td>
                <td>{tx.amount}</td>
                <td>{this.createB24TxAuthCodeField(tx)}</td>
                <td>
                    {tx.date ? this.formatDate(new Date(tx.date)) + ' ' + new Date(tx.date).toLocaleTimeString() : ''}
                </td>
                <td>{tx.status} {this.createB24TxFixButton(tx)}</td>
                <td>{this.createRefundStatus(tx)}</td>
                <td><ReactJson src={tx} name={false} collapsed={true}/></td>
            </tr>
        ));
    }

    setB24TxSearchResultsAuthCodeMap = (results) => {
        let authCodes = {};

        results.forEach(tx => {
            authCodes[tx.txUid] = tx.respAuth || tx.txAuth;
        });

        this.setState({authCodes: authCodes});
    }

    changeB24TxAuthCode = (txUid, newValue) => {
        this.setState(prevState => ({
            authCodes: {
                ...prevState.authCodes,
                [txUid]: newValue
            }
        }));
    }

    onFixB24TxResponse = (tx) => {
        this.configureTxOperationNotificationBar(`Transacción B24 ${tx.txUid} ha sido reparada`, DIALOG_STATUS.SUCCESS);
        this.setState(prevState => {
            let updatedMap = prevState.searchB24TxResultMap ? {...prevState.searchB24TxResultMap} : {};
            updatedMap[tx.txUid] = tx;
            return {searchB24TxResultMap: updatedMap};
        }, () => {
            this.clearFixB24TxConfirmation();
            setTimeout(() => {
                this.configureTxOperationNotificationBar(undefined);
            }, 5000);
        });
    }

    onRefundB24TxResponse = (tx, response) => {
        if (response.prosaResponse && response.prosaResponse.txMsgStatus === 'ACCEPTED') {

            this.setState(prevState => {
                let updatedMap = prevState.searchB24TxResultMap ? {...prevState.searchB24TxResultMap} : {};
                tx.refundAmount = response.prosaResponse.amount;
                updatedMap[tx.txUid] = tx;
                return {searchB24TxResultMap: updatedMap};
            });

            if (response.prosaResponse.amount < tx.amount) {
                this.configureTxOperationNotificationBar(`Transacción B24 ${tx.txUid} ha sido parcialmente devuelta`, DIALOG_STATUS.SUCCESS, response);
            } else {
                this.configureTxOperationNotificationBar(`Transacción B24 ${tx.txUid} ha sido devuelta`, DIALOG_STATUS.SUCCESS, response);
            }
        } else {
            this.configureTxOperationNotificationBar(`Transacción B24 ${tx.txUid} no se devolvió`, DIALOG_STATUS.ERROR, response);
        }

        this.clearRefundB24TxConfirmation();
        setTimeout(() => {
            this.configureTxOperationNotificationBar(undefined);
        }, 10000);
    }

    fixB24Tx = (tx, authCode) => {
        this.setState({
            fixB24TxConfirmation: {
                tx: tx,
                authCode: authCode,
                header: `Reparar transacción B24 ${tx.txUid} con código de autorización ${authCode}`,
                body: `Esta operación intentará reparar transacción B24 ${tx.txUid}, la cual tiene un estado inconsistente.\n
                    Track2: ${tx.t2m}\n
                    Autorización: ${authCode}\n
                    Monto: ${tx.amount}\n
                    Afiliación: ${tx.fiid}\n
                    B24 TSN: ${tx.tsn}\n
                    Fecha: ${tx.date ? this.formatDate(new Date(tx.date)) + ' ' + new Date(tx.date).toLocaleTimeString() : ''}`,
                operation: () => fixB24Tx({txUid: tx.txUid, authCode: authCode})
                    .then(resp => resp.data)
                    .then(this.onFixB24TxResponse)
                    .catch(this.onError)
            }
        });
    }

    refundB24Tx = (tx) => {
        this.setState({
            refundB24TxConfirmation: {
                tx: tx,
                authCode: tx.txAuth,
                header: `Devolver transacción B24 ${tx.txUid} con código de autorización ${tx.txAuth}`,
                body: `Esta operación intentará DEVOLVER la transacción B24: ${tx.txUid}, MONTO: ${tx.amount}.\n
                    Track2: ${tx.t2m}\n
                    Autorización: ${tx.txAuth}\n
                    Monto: ${tx.amount}\n
                    Afiliación: ${tx.fiid}\n
                    Fecha: ${tx.date ? this.formatDate(new Date(tx.date)) + ' ' + new Date(tx.date).toLocaleTimeString() : ''}`,
                operation: () => refundB24Tx(
                    {
                        txUid: tx.txUid,
                        authCode: tx.txAuth,
                        t2m: tx.t2m,
                        fiid: tx.fiid,
                        tsn: tx.terminalSn,
                        amount: tx.amount
                    })
                    .then(resp => resp.data)
                    .then(resp => this.onRefundB24TxResponse(tx, resp))
                    .catch(this.onError)
            }
        });
    }

    createB24TxFixButton = (tx) => {
        let isFixable = this.isB24TxFixable(tx);
        let isRefundable = this.isB24Refundable(tx);
        let authCode = this.state.authCodes && this.state.authCodes[tx.txUid] ? this.state.authCodes[tx.txUid] : '';

        if (isFixable) {
            return (
                <ButtonGroup className="ml-2">
                    <Button
                        disabled={!(this.validateAuthCode(authCode) || (typeof current === 'string' && authCode === ''))}
                        variant='danger' onClick={() => this.fixB24Tx(tx, authCode)}>Reparar {tx.txUid}</Button>
                </ButtonGroup>
            );
        } else if (isRefundable) {
            return (
                <ButtonGroup className="ml-2">
                    <Button
                        variant='warning' onClick={() => this.refundB24Tx(tx)}>Devolver {tx.txUid}</Button>
                </ButtonGroup>
            );
        } else {
            return null;
        }
    }

    createRefundStatus = (tx) => {
        return typeof tx.refundAmount === 'number' && tx.refundAmount > 0
            ? (tx.refundAmount >= tx.amount
                ? 'DEVUELTA'
                : `DEVUELTA PARCIAL (${tx.refundAmount})`)
            : 'NO DEVUELTA';
    }

    createB24TxAuthCodeField = (tx) => {
        let isFixable = this.isB24TxFixable(tx);
        let authCode = this.state.authCodes && this.state.authCodes[tx.txUid] ? this.state.authCodes[tx.txUid] : '';
        return isFixable ? (
            <>
                <Form.Control
                    type="text"
                    onChange={val => this.changeB24TxAuthCode(tx.txUid, val.target.value)}
                    value={authCode}
                    isInvalid={!(this.validateAuthCode(authCode) || (typeof current === 'string' && authCode === ''))}
                />
                <Form.Control.Feedback type="invalid">
                    Ingresa un número de autorización válido: NNNNNN.
                </Form.Control.Feedback>
            </>

        ) : (tx.respAuth != null ? tx.respAuth : tx.txAuth);
    }

    isB24TxFixable = (tx) => {
        let hasNullRequest = !tx.reqId && tx.reqId !== 0;
        let hasNullResponse = !tx.respId && tx.respId !== 0;
        let hasNoTxAuth = !tx.txAuth || tx.txAuth.trim() === '';
        let hasNoRespAuth = !tx.respAuth || tx.respAuth.trim() === '';
        let isError = tx.status === 'ERROR';
        let isRejectedWithAuth0 = tx.status === 'REJECTED' && (!tx.reqIdStr || tx.reqIdStr.trim() === '000000')

        return (hasNullRequest || hasNullResponse || hasNoTxAuth || hasNoRespAuth || isError || isRejectedWithAuth0);
    }

    isB24Refundable = (tx) => {
        let isNotFixable = !this.isB24TxFixable(tx);
        let isAccepted = tx.status === 'ACCEPTED';
        let isSale = tx.type === 'SALE';
        let isRefunded = typeof tx.amount === 'number' &&
            typeof tx.refundAmount === 'number'

        return (isNotFixable && isAccepted && isSale && !isRefunded);
    }

    hasRefund = (tx) => {
        let isRefunded = typeof tx.amount === 'number' &&
            typeof tx.refundAmount === 'number';

        return isRefunded;
    }

    updateFieldCompleteness = () => {
        // We're excluding endDate
        const {startDate, isT2mValid, isAuthCodeValid, isValidAmount, isFiidValid, isBpTransactionIdValid} = this.state;
        const areFieldsComplete = startDate != null && isT2mValid && isAuthCodeValid && isValidAmount && isFiidValid && isBpTransactionIdValid;
        this.setState({areFieldsComplete});
    }

    clearFixB24TxConfirmation = () => {
        this.setState({
            fixB24TxConfirmation: {}
        });
    }

    clearRefundB24TxConfirmation = () => {
        this.setState({
            refundB24TxConfirmation: {}
        });
    }

    b24TxFixConfirmationDialog = () => {
        let tx = this.state.fixB24TxConfirmation && this.state.fixB24TxConfirmation['tx'] !== undefined ? this.state.fixB24TxConfirmation['tx'] : null;
        let headerText = this.state.fixB24TxConfirmation && this.state.fixB24TxConfirmation['header'] !== undefined ? this.state.fixB24TxConfirmation['header'] : null;
        let bodyText = this.state.fixB24TxConfirmation && this.state.fixB24TxConfirmation['body'] !== undefined ? this.state.fixB24TxConfirmation['body'] : null;
        let operation = this.state.fixB24TxConfirmation && this.state.fixB24TxConfirmation['operation'] !== undefined ? this.state.fixB24TxConfirmation['operation'] : null;

        return (
            <Modal
                show={tx != null}
                onHide={() => this.closeConfirmDialog}
                backdrop='static'
                keyboard={false}>
                {
                    headerText != null ?
                        <Modal.Header>
                            <Modal.Title>
                                <Alert variant='dark'>
                                    <Alert.Heading>{headerText}</Alert.Heading>
                                </Alert>
                            </Modal.Title>
                        </Modal.Header> : null
                }
                <Modal.Body>
                    {bodyText && bodyText.split('\n').map((line, index) => (
                        <span key={index}>
                            {line}
                            <br/>
                        </span>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.clearFixB24TxConfirmation}>Cancelar</Button>
                    <Button variant="primary" onClick={() => operation()}>Reparar</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    b24TxRefundConfirmationDialog = () => {
        let tx = this.state.refundB24TxConfirmation && this.state.refundB24TxConfirmation['tx'] !== undefined ? this.state.refundB24TxConfirmation['tx'] : null;
        let headerText = this.state.refundB24TxConfirmation && this.state.refundB24TxConfirmation['header'] !== undefined ? this.state.refundB24TxConfirmation['header'] : null;
        let bodyText = this.state.refundB24TxConfirmation && this.state.refundB24TxConfirmation['body'] !== undefined ? this.state.refundB24TxConfirmation['body'] : null;
        let operation = this.state.refundB24TxConfirmation && this.state.refundB24TxConfirmation['operation'] !== undefined ? this.state.refundB24TxConfirmation['operation'] : null;

        return (
            <Modal
                show={tx != null}
                onHide={() => this.closeConfirmDialog}
                backdrop='static'
                keyboard={false}>
                {
                    headerText != null ?
                        <Modal.Header>
                            <Modal.Title>
                                <Alert variant='dark'>
                                    <Alert.Heading>{headerText}</Alert.Heading>
                                </Alert>
                            </Modal.Title>
                        </Modal.Header> : null
                }
                <Modal.Body>
                    {bodyText && bodyText.split('\n').map((line, index) => (
                        <span key={index}>
                            {line}
                            <br/>
                        </span>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.clearRefundB24TxConfirmation}>Cancelar</Button>
                    <Button variant="primary" onClick={() => operation()}>Devolver</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    handleB24TxFile = async (event, fileType) => {
        const fileInput = event.target; // Store input element reference
        const file = fileInput.files[0];
        if (!file) return; // Exit if no file selected

        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, {type: 'buffer'});

        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];

        const jsonB24TxArray = XLSX.utils.sheet_to_json(worksheet, {
            header: ['bpTxId', 't2m', 'date', 'time', 'txAuth', 'amount', 'fiid', 'type'],
            range: 1,
            raw: false,
            blankrows: false
        });

        jsonB24TxArray.forEach((row, idx) => {
            // Trim all string values in the row
            Object.keys(row).forEach(key => {
                if (typeof row[key] === 'string') {
                    row[key] = row[key].trim();

                    if (key === 'amount') {
                        row[key] = row[key].replace(/,/g, '');
                    }
                }
            });

            // Combines date and time into one string, handles single-digit hours correctly
            const dateTimeString = row.date + ' ' + row.time;

            const parts = dateTimeString.split(/[/ :]/);

            const year = parseInt(parts[2], 10);
            const month = parseInt(parts[1], 10) - 1; // Adjust month to 0-indexed
            const day = parseInt(parts[0], 10);
            const hours = parseInt(parts[3], 10) || 0; // Handle single-digit hours correctly
            const minutes = parseInt(parts[4], 10);
            const seconds = parseInt(parts[5], 10);

            row.dateTime = new Date(year, month, day, hours, minutes, seconds);
            row.rowIndex = idx;
            row.status = 'DESCONOCIDO';
            row.type = fileType;
        });

        this.setState(prevState => {
            return {
                b24TxFileProcessing: {
                    ...prevState.b24TxFileProcessing,
                    jsonB24TxMap: jsonB24TxArray.reduce((acc, tx) => ({...acc, [tx.rowIndex]: tx}), {})
                }
            }
        });

        // Reset the file input after processing
        fileInput.value = '';
    }

    createB24TxTableFromFile = () => {
        const b24TxFileProcessing = this.state.b24TxFileProcessing || {};
        const jsonB24TxMap = b24TxFileProcessing.jsonB24TxMap || {};
        const jsonB24TxArray = Object.values(jsonB24TxMap);

        const getRowBackgroundColor = (tx) => {
            if (tx.b24Tx && Array.isArray(tx.b24Tx.data) && tx.b24Tx.data.length === 1 && this.isB24Refundable(tx.b24Tx.data[0])) {
                return 'lightgreen';
            } else if (tx.status === 'MULTIPLES' || tx.status === 'ENCONTRADA') {
                return 'orange';
            } else if (tx.status === 'DEVUELTA' || tx.status?.startsWith('DEVUELTA PARCIAL')) {
                return '#ADD8E6'; // light blue
            } else {
                return null;
            }
        };

        return jsonB24TxArray.map(tx => {
            const backgroundColor = getRowBackgroundColor(tx);
            const formattedDateTime = tx.dateTime ? this.formatDate(tx.dateTime) + ' ' + new Date(tx.dateTime).toLocaleTimeString() : '';

            return (
                <tr key={tx.rowIndex} style={{backgroundColor}}>
                    <td>{tx.bpTxId}</td>
                    <td>{tx.t2m}</td>
                    <td>{formattedDateTime}</td>
                    <td>{tx.txAuth}</td>
                    <td>{tx.amount}</td>
                    <td>{tx.fiid}</td>
                    <td>{tx.status}</td>
                    <td>{tx.type}</td>
                </tr>
            );
        });
    };

    cleanB24TxFileProcessing = () => {
        this.setState({
            b24TxFileProcessing: {}
        });
    }

    isB24TxFileSearchable = () => {
        const b24TxFileProcessing = this.state.b24TxFileProcessing || {};
        const jsonB24TxMap = b24TxFileProcessing.jsonB24TxMap || {};
        const currentB24TxBeingProcessed = b24TxFileProcessing.currentB24TxBeingProcessed;
        const isProcessing = currentB24TxBeingProcessed && (typeof currentB24TxBeingProcessed === 'object');
        const hasB24Txs = Object.keys(jsonB24TxMap).length > 0;

        return hasB24Txs && !isProcessing;
    }

    isB24TxFileCleanable = () => {
        const b24TxFileProcessing = this.state.b24TxFileProcessing || {};
        const currentB24TxBeingProcessed = b24TxFileProcessing.currentB24TxBeingProcessed;
        const isProcessing = currentB24TxBeingProcessed && (typeof currentB24TxBeingProcessed === 'object')

        return !isProcessing;
    }

    findB24TxFromFile = async () => {
        const {jsonB24TxMap} = this.state.b24TxFileProcessing || {};
        const jsonB24TxArray = Object.values(jsonB24TxMap);

        for (const tx of jsonB24TxArray) {
            await this.findB24TxRow(tx);
        }

        this.updateB24TxInJsonMap(undefined, false);
    }

    async findB24TxRow(tx) {
        tx.status = 'BUSCANDO...';

        await this.updateB24TxInJsonMap(tx, true);

        try {
            const params = {
                startDate: this.formatDate(tx.dateTime),
                t2m: tx.t2m,
                authCode: tx.txAuth,
                fiid: tx.fiid,
                amount: tx.amount,
                bpTxId: tx.bpTxId
            };

            if (tx.type === FILE_TYPES.BORNEO) {
                tx.kushkiTx = await getBorneoTxById(params);
            } else if (tx.type === FILE_TYPES.B24) {
                tx.b24Tx = await getB24TxById(params);
            } else {
                throw new Error('Unsupported transaction type');
            }

            tx.status = this.createTxStatus(tx);
        } catch (error) {
            tx.searchError = error;
            tx.status = 'NO ENCONTRADO';
        }

        await this.updateB24TxInJsonMap(tx, true);

        return tx;
    }

    createTxStatus = (tx) => {
        if (tx.type === FILE_TYPES.BORNEO) {
            // ##################### BORNEO KUSHKI
            if (Array.isArray(tx.kushkiTx) && tx.kushkiTx.length === 1) {
                const result = tx.kushkiTx[0];

                if ('statusBorneo' in result && 'authNumberBorneo' in result && 'affiliationBorneo' in result) {
                    return 'ENCONTRADA';
                } else {
                    return 'INVÁLIDA';
                }
            } else if (Array.isArray(tx.kushkiTx) && tx.kushkiTx.length > 1) {
                return 'MULTIPLES';
            } else {
                return 'NO ENCONTRADA';
            }
        } else if (tx.type === FILE_TYPES.B24) {
            // ##################### HYDRA B24
            if (Array.isArray(tx.b24Tx.data) && tx.b24Tx.data.length === 1) {
                const result = tx.b24Tx.data[0];

                // Check if refundAmount and amount are present
                if ('refundAmount' in result && 'amount' in result) {
                    // Check if refundAmount is greater than or equal to amount
                    if (result.refundAmount >= result.amount) {
                        return 'DEVUELTA';
                    } else {
                        return `DEVUELTA PARCIAL (${result.refundAmount})`;
                    }
                } else {
                    return 'ENCONTRADA';
                }
            } else if (Array.isArray(tx.b24Tx.data) && tx.b24Tx.data.length > 1) {
                return 'MULTIPLES';
            } else {
                return 'NO ENCONTRADO';
            }
        } else {
            return 'NO ENCONTRADO';
        }
    };


    fixAndRefundB24TxFromFile = async (jsonB24TxArray) => {
        console.log('fixAndRefundB24TxFromFile');
        for (const tx of jsonB24TxArray) {
            if (tx.type === FILE_TYPES.B24) {
                // Fix only applied for B24
                await this.fixB24TxRow(tx);
            }

            await this.refundB24TxRow(tx);
        }

        this.updateB24TxInJsonMap(undefined, false);
    }

    async fixB24TxRow(tx) {
        if (this.isB24TxFixable(tx.b24Tx.data[0])) {
            tx.status = 'REPARANDO...';

            await this.updateB24TxInJsonMap(tx, true);

            try {
                const b24Tx = tx.b24Tx.data[0];
                const params = {
                    txUid: b24Tx.txUid,
                    authCode: tx.txAuth
                };
                let fixedResp = await fixB24Tx(params);
                tx.fixedB24Tx = fixedResp && typeof fixedResp.data === 'object' && fixedResp.data !== null ? fixedResp.data : undefined;
                tx.status = 'REPARADA';
            } catch (error) {
                tx.fixError = error;
                tx.status = 'NO SE PUDO REPARAR';
            }
        } else {
            tx.status = 'NO REQUIERE REPARACIÓN';
            tx.fixedB24Tx = tx.b24Tx.data[0];
            this.updateB24TxInJsonMap(tx, true);
        }

        await this.updateB24TxInJsonMap(tx, true);

        return tx;
    }

    async refundB24TxRow(tx) {
        if (tx.type === FILE_TYPES.B24) {
            if (tx.fixedB24Tx) {
                tx.status = 'DEVOLVIENDO...';

                await this.updateB24TxInJsonMap(tx, true);

                try {
                    const params = {
                        txUid: tx.fixedB24Tx.txUid,
                        authCode: tx.fixedB24Tx.txAuth,
                        t2m: tx.fixedB24Tx.t2m,
                        fiid: tx.fixedB24Tx.fiid,
                        tsn: tx.fixedB24Tx.terminalSn,
                        amount: tx.fixedB24Tx.amount
                    };
                    tx.b24TxRefundResult = await refundB24Tx(params);
                    tx.status = this.createB24TxRowRefundStatus(tx);
                } catch (error) {
                    tx.refundError = error;
                    tx.status = 'NO SE PUDO DEVOLVER';
                }
            } else {
                tx.status = 'NO DEVUELTA';
            }
        } else if (tx.type === FILE_TYPES.BORNEO) {
            let kushkiTx = tx.kushkiTx[0].tx;
            try {
                const params = {
                    txUid: `${kushkiTx.txId}`,
                };

                tx.borneoTxRefundResult = await refundBorneoTx(params);
                tx.status = this.createB24TxRowRefundStatus(tx);
            } catch (error) {
                tx.refundError = error;
                tx.status = 'NO SE PUDO DEVOLVER';
            }
        } else {
            throw new Error('Unsupported transaction type');
        }

        await this.updateB24TxInJsonMap(tx, true);

        return tx;
    }

    createB24TxRowRefundStatus = (tx) => {
        let txMsgStatus = '';
        if (tx.type === FILE_TYPES.B24) {
            txMsgStatus = (tx.b24TxRefundResult?.data?.prosaResponse?.txMsgStatus || '');
        } else if (tx.type === FILE_TYPES.BORNEO) {
            txMsgStatus = (tx.borneoTxRefundResult?.txMsgStatus || '');
        }

        if (txMsgStatus === 'ACCEPTED') {
            if (tx.type === FILE_TYPES.B24) {
                tx.b24Tx.data[0].refundAmount = tx.b24TxRefundResult?.data?.prosaResponse?.amount;
            }

            return 'DEVUELTA';
        } else if (txMsgStatus) {
            return txMsgStatus;
        } else {
            return 'ERROR (BUSCAR DE NUEVO)';
        }
    };

    updateB24TxInJsonMap = (tx, isBeingProcessed) => {
        this.setState(prevState => {
            const b24TxFileProcessing = prevState.b24TxFileProcessing || {};
            const updatedMap = {...b24TxFileProcessing.jsonB24TxMap};

            if (tx !== undefined) {
                updatedMap[tx.rowIndex] = tx;
            }

            const newState = {
                b24TxFileProcessing: {
                    ...b24TxFileProcessing,
                    jsonB24TxMap: updatedMap,
                }
            };

            if (isBeingProcessed !== undefined) {
                newState.b24TxFileProcessing.currentB24TxBeingProcessed = isBeingProcessed ? tx : undefined;
            }

            return newState;
        });
    }

    getRefundB24TxFromFileButton = () => {
        const b24TxFileProcessing = this.state.b24TxFileProcessing || {};
        const jsonB24TxMap = b24TxFileProcessing.jsonB24TxMap || {};
        const currentB24TxBeingProcessed = b24TxFileProcessing.currentB24TxBeingProcessed;
        const isProcessing = currentB24TxBeingProcessed && (typeof currentB24TxBeingProcessed === 'object')

        const jsonB24TxArray = Object.values(jsonB24TxMap);

        const filteredTxArray = jsonB24TxArray.filter(tx => {
                if (tx.type === FILE_TYPES.B24) {
                    return tx.b24Tx && Array.isArray(tx.b24Tx.data) && tx.b24Tx.data.length === 1 && !this.hasRefund(tx.b24Tx.data[0])
                } else if (tx.type === FILE_TYPES.BORNEO) {
                    return Array.isArray(tx.kushkiTx) && tx.kushkiTx.length === 1 && tx.kushkiTx[0].statusBorneo === 'APPROVAL';
                } else {
                    return false;
                }
            }
        );

        if (filteredTxArray.length > 0) {
            return (
                <ButtonGroup className="mr-2">
                    <Button variant={'danger'}
                            onClick={() => this.fixAndRefundB24TxFromFile(filteredTxArray)}
                            disabled={isProcessing}>
                        Devolver transacciones
                    </Button>
                </ButtonGroup>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <>
                <Card className="bg-info text-white">
                    <Card.Header>
                        <h4><b>Panel de operaciones B24</b></h4>
                    </Card.Header>
                </Card>
                {
                    this.state.txOperationNotificationBar ?
                        <Alert key={1} variant={this.state.txOperationNotificationBar.type}
                               onClose={() => this.configureTxOperationNotificationBar(undefined)} dismissible>
                            <b>{this.state.txOperationNotificationBar.message}</b>
                            {this.state.txOperationNotificationBar.object ?
                                <ReactJson src={this.state.txOperationNotificationBar.object} name={false}
                                           collapsed={false}/> : null}
                        </Alert> : null
                }
                <Modal
                    show={this.state.spinner}
                    backdrop='static'
                    keyboard={false}>
                    <Modal.Body>
                        <div>
                            <b> Buscando... </b>
                            <Spinner animation="grow" size="sm" role="status">
                                <span className="sr-only"/>
                            </Spinner>
                        </div>
                    </Modal.Body>
                </Modal>
                {
                    this.b24TxFixConfirmationDialog()
                }
                {
                    this.b24TxRefundConfirmationDialog()
                }
                <Tabs defaultActiveKey={TAB.B24_FILE_PROCESSING} id="uncontrolled-tab-example"
                      onSelect={this.onChangeTabs}>
                    <Tab eventKey={TAB.B24_FILE_PROCESSING} title="Procesamiento de archivo B24">
                        <Card>
                            <Card.Body>
                                <Form.Group as={Row}>
                                    <Col sm="6">
                                        <Form>
                                            <Form.File id="custom-file-input" custom>
                                                <Form.File.Input accept=".xlsx, .xls, .csv"
                                                                 onChange={(event) => this.handleB24TxFile(event, FILE_TYPES.B24)}/>
                                                <Form.File.Label data-browse="[B24] Seleccionar archivo">
                                                    Choose file (Excel or CSV)
                                                </Form.File.Label>
                                            </Form.File>
                                        </Form>
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar>
                                    <ButtonGroup className="mr-2">
                                        <OverlayTrigger overlay={<Tooltip>Limpiar transacciones</Tooltip>}>
                                            <Button variant='primary'
                                                    disabled={!this.isB24TxFileCleanable()}
                                                    onClick={this.cleanB24TxFileProcessing}><TrashIcon/></Button>
                                        </OverlayTrigger>
                                    </ButtonGroup>
                                    <ButtonGroup className="mr-2">
                                        <Button variant={'warning'}
                                                onClick={this.findB24TxFromFile}
                                                disabled={!this.isB24TxFileSearchable()}>
                                            Buscar transacciones
                                        </Button>
                                    </ButtonGroup>
                                    {
                                        this.getRefundB24TxFromFileButton()
                                    }
                                </ButtonToolbar>
                            </Card.Body>
                        </Card>
                        <Table striped bordered hover>
                            <thead className="thead-dark">
                            <tr>
                                <th>BP Id</th>
                                <th>Tarjeta</th>
                                <th>Fecha</th>
                                <th>Autorización</th>
                                <th>Monto devolución (MXN)</th>
                                <th>Afiliación</th>
                                <th>Estatus</th>
                                <th>Tipo de Operación</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.createB24TxTableFromFile()}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey={TAB.BORNEO_FILE_PROCESSING} title="Procesamiento de archivo Borneo">
                        <Card>
                            <Card.Body>
                                <Form.Group as={Row}>
                                    <Col sm="6">
                                        <Form>
                                            <Form.File id="custom-file-input" custom>
                                                <Form.File.Input accept=".xlsx, .xls, .csv"
                                                                 onChange={(event) => this.handleB24TxFile(event, FILE_TYPES.BORNEO)}/>
                                                <Form.File.Label data-browse="[Borneo] Seleccionar archivo">
                                                    Choose file (Excel or CSV)
                                                </Form.File.Label>
                                            </Form.File>
                                        </Form>
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar>
                                    <ButtonGroup className="mr-2">
                                        <OverlayTrigger overlay={<Tooltip>Limpiar transacciones</Tooltip>}>
                                            <Button variant='primary'
                                                    disabled={!this.isB24TxFileCleanable()}
                                                    onClick={this.cleanB24TxFileProcessing}><TrashIcon/></Button>
                                        </OverlayTrigger>
                                    </ButtonGroup>
                                    <ButtonGroup className="mr-2">
                                        <Button variant={'warning'}
                                                onClick={this.findB24TxFromFile}
                                                disabled={!this.isB24TxFileSearchable()}>
                                            Buscar transacciones
                                        </Button>
                                    </ButtonGroup>
                                    {
                                        this.getRefundB24TxFromFileButton()
                                    }
                                </ButtonToolbar>
                            </Card.Body>
                        </Card>
                        <Table striped bordered hover>
                            <thead className="thead-dark">
                            <tr>
                                <th>BP Id</th>
                                <th>Tarjeta</th>
                                <th>Fecha</th>
                                <th>Autorización</th>
                                <th>Monto devolución (MXN)</th>
                                <th>Afiliación</th>
                                <th>Estatus</th>
                                <th>Tipo de Operación</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.createB24TxTableFromFile()}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey={TAB.SEARCH} title="Búsqueda B24" disabled="true">
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2">Fecha</Form.Label>
                                        <Col sm="6">
                                            <InputGroup>
                                                <FormControl
                                                    placeholder={this.formatDatePickerText(this.state.startDate, 'inicial')}
                                                    disabled={true}/>
                                                <InputGroup.Append>
                                                    <DatePicker selected={this.state.startDate}
                                                                onChange={date => this.setStartDate(date)}
                                                                dateFormat={FORMAT_DATEPICKER}
                                                                customInput={<CustomInputDatepicker/>}
                                                    />
                                                </InputGroup.Append>
                                                <FormControl
                                                    placeholder={this.formatDatePickerText(this.state.endDate, 'final')}
                                                    disabled={true}/>
                                                <InputGroup.Append>
                                                    <DatePicker selected={this.state.endDate}
                                                                onChange={date => this.setEndDate(date)}
                                                                dateFormat={FORMAT_DATEPICKER}
                                                                customInput={<CustomInputDatepicker/>}
                                                    />
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2">Track 2 (máscara)</Form.Label>
                                        <Col sm="6">
                                            <Form.Control
                                                type="text"
                                                onChange={val => this.setT2Mask(val.target.value)}
                                                value={this.state.t2m}
                                                isInvalid={!this.state.isT2mValid}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Introduce el formato de máscara track 2: NNNNNN******NNNN.
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2">Num. Autorización</Form.Label>
                                        <Col sm="3">
                                            <Form.Control
                                                type="text"
                                                onChange={val => this.setAuthCode(val.target.value)}
                                                value={this.state.authCode}
                                                isInvalid={!this.state.isAuthCodeValid}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Ingresa un número de autorización válido: NNNNNN.
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2">Num. Afiliación</Form.Label>
                                        <Col sm="3">
                                            <Form.Control
                                                type="text"
                                                onChange={val => this.setFiid(val.target.value)}
                                                value={this.state.fiid}
                                                isInvalid={!this.state.isFiidValid}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Ingresa un número de afiliación válido: NNNNNNN.
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2">Monto</Form.Label>
                                        <Col sm="3">
                                            <Form.Control
                                                type="text"
                                                onChange={val => this.setAmount(val.target.value)}
                                                value={this.state.amount}
                                                isInvalid={!this.state.isValidAmount}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Introduce un monto valido: Un monto con hasta dos posiciones decimales.
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <ButtonToolbar>
                                        <ButtonGroup className="mr-2">
                                            <OverlayTrigger overlay={<Tooltip>Limpiar filtros</Tooltip>}>
                                                <Button variant='primary'
                                                        onClick={this.clearB24TxSearchFields}><TrashIcon/></Button>
                                            </OverlayTrigger>
                                        </ButtonGroup>
                                        <ButtonGroup className="mr-2">
                                            <Button variant={this.state.areFieldsComplete ? 'warning' : 'danger'}
                                                    onClick={this.searchB24Tx}
                                                    disabled={!this.state.areFieldsComplete}>
                                                Buscar tx B24
                                            </Button>
                                        </ButtonGroup>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Table striped bordered hover>
                            <thead className="thead-dark">
                            <tr>
                                <th>B24 Id</th>
                                <th>FIID</th>
                                <th>T2M</th>
                                <th>Monto</th>
                                <th>Autorización</th>
                                <th>B24 Fecha</th>
                                <th>B24 Estatus</th>
                                <th>B24 Devolución</th>
                                <th>B24 Metadata</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.createB24TxSearchResults()}
                            </tbody>
                        </Table>
                    </Tab>
                </Tabs>
            </>
        );
    }
}